import React, { useContext } from 'react';
import { Button, Accordion, Card, AccordionContext, useAccordionToggle } from 'react-bootstrap';
import { isAfter, endOfMonth, endOfDay, isSaturday, isSameYear } from 'date-fns';
import { capitalize, formatDigits, jsonEqual, notNullOrUndefined } from '../../util/utils';
import '../../libraryStyleOverrides/Tooltip.scss';
import './UsageTable.scss';
import { UsageCell } from './UsageCell';
import { TemperatureCell } from './TemperatureCell';
import { DateCell } from './DateCell';
import { GraphRecord } from '../TimeOfUseGraph/GraphRecord';

interface ExpandButtonProps {
  eventKey: string;
  children?: React.ReactNode;
}
function ExpandContractButton(props: ExpandButtonProps) {
  const currentEventKey = useContext(AccordionContext);
  const decoratedOnClick = useAccordionToggle(props.eventKey);

  const isCurrentEventKey = currentEventKey === props.eventKey;

  if (isCurrentEventKey) {
    return (
      <Button
        onClick={decoratedOnClick}
        className="accordion expanded intervalButton"
        aria-expanded="true"
        data-track="usage"
        data-track-detail="usage shiftandsave"
        data-track-action="click"
        data-track-sub-action="contract table"
      >
        <span className="sr-only">Hide </span>
        {props.children}
      </Button>
    );
  }

  return (
    <Button
      onClick={decoratedOnClick}
      className="accordion intervalButton"
      aria-expanded="false"
      data-track="usage"
      data-track-detail="usage shiftandsave"
      data-track-action="click"
      data-track-sub-action="expand table"
    >
      <span className="sr-only">Show </span>
      {props.children}
    </Button>
  );
}

/**
 * Only show days that are today or earlier
 *
 * @param midpoint
 * @returns
 */
function includeDay(midpoint: number): boolean {
  const date = endOfDay(new Date());
  return !isAfter(midpoint, date);
}

/**
 * Only show months that are this month or earlier
 *
 * @param midpoint
 * @returns
 */
function includeMonth(midpoint: number): boolean {
  const date = endOfMonth(new Date());
  return !isAfter(midpoint, date);
}

function includeInterval(interval: string, midpoint: number): boolean {
  if (interval === 'day') {
    return includeDay(midpoint);
  }

  if (interval === 'month') {
    return includeMonth(midpoint);
  }

  // Don't do any filtering of hours
  return true;
}

function temperatureSubheader(interval: string): JSX.Element {
  if (interval === 'hour') {
    return null;
  }

  return (
    <React.Fragment>
      <th scope="col" className="subheader temperature low">
        <span className="sr-only">Low Temperature, in Degrees Fahrenheit</span>
        <span className="note">Low</span>
      </th>
      <th scope="col" className="subheader temperature high">
        <span className="sr-only">High Temperature, in Degrees Fahrenheit</span>
        <span className="note">High</span>
      </th>
    </React.Fragment>
  );
}

function temperatureHeader(interval: string): JSX.Element {
  if (interval === 'hour') {
    return (
      <th scope="col" className="temperature">
        <span className="sr-only">Temperature, in Degrees Fahrenheit</span>
        <span aria-hidden="true">
          <span className="d-none d-lg-inline">Temperature</span>
          <span className="d-lg-none">Temp.</span>
        </span>
      </th>
    );
  }

  return (
    <th colSpan={2} className="hasSubheader">
      <span aria-hidden="true">
        <span className="d-none d-lg-inline">Temperature</span>
        <span className="d-lg-none">Temp.</span>
      </span>
    </th>
  );
}

function renderHeader(interval: string, hasTimeOfUseData: boolean, hasSuperOffPeakData: boolean, hasStandardUsage: boolean): JSX.Element {
  let rowspan = 1;
  if (interval !== 'hour') {
    rowspan = 2;
  }

  const usageHeaders = [];
  if (hasTimeOfUseData) {
    if (hasSuperOffPeakData) {
      usageHeaders.push(
        <th key="superOffpeak" scope="col" rowSpan={rowspan}>
          Super Off-Peak
          <br />
          <span className="note">
            <span className="sr-only">kilowatt hours</span>
            <span aria-hidden="true">kWh</span>
          </span>
        </th>
      );
    }
    usageHeaders.push(
      <th key="offpeak" scope="col" rowSpan={rowspan}>
        Off-Peak
        <br />
        <span className="note">
          <span className="sr-only">kilowatt hours</span>
          <span aria-hidden="true">kWh</span>
        </span>
      </th>
    );
    usageHeaders.push(
      <th key="peak" scope="col" rowSpan={rowspan}>
        Peak
        <br />
        <span className="note">
          <span className="sr-only">kilowatt hours</span>
          <span aria-hidden="true">kWh</span>
        </span>
      </th>
    );
    if (hasStandardUsage) {
      usageHeaders.push(
        <th key="standard" scope="col" rowSpan={rowspan}>
          <span className="sr-only">Other Rates</span>
          <span aria-hidden="true">
            <span className="d-none d-lg-inline">Other Rates</span>
            <span className="d-lg-none">Other</span>
          </span>{' '}
          <sup>1</sup>
          <br />
          <span className="note">
            <span className="sr-only">kilowatt hours</span>
            <span aria-hidden="true">kWh</span>
          </span>
        </th>
      );
    }
  } else {
    if (hasStandardUsage) {
      usageHeaders.push(
        <th key="standard" scope="col" rowSpan={rowspan}>
          <span className="sr-only">Electric Usage</span>
          <span aria-hidden="true">
            <span className="d-none d-lg-inline">Electric Usage</span>
            <span className="d-lg-none">Usage</span>
          </span>
          <br />
          <span className="note">
            <span className="sr-only">kilowatt hours</span>
            <span aria-hidden="true">kWh</span>
          </span>
        </th>
      );
    }
  }

  return (
    <thead>
      <tr>
        <th scope="col" className="time" rowSpan={rowspan}>
          {capitalize(interval)}
        </th>
        {usageHeaders}
        {/* <th scope="col" rowSpan={rowspan}>
          Cost <sup>2</sup>
        </th> */}
        {temperatureHeader(interval)}
      </tr>
      <tr>{temperatureSubheader(interval)}</tr>
    </thead>
  );
}

function getColumns(interval: string, hasTimeOfUseData: boolean, hasSuperOffPeakData: boolean, hasStandardUsage: boolean): Map<string, string> {
  const columns = new Map<string, string>();

  columns.set('timeColumn', 'timeColumn');

  if (hasTimeOfUseData) {
    if (hasSuperOffPeakData) {
      columns.set('superOffpeakColumn', 'superOffpeakColumn');
    }
    columns.set('offpeakColumn', 'offpeakColumn');
    columns.set('peakColumn', 'peakColumn');
  }

  if (hasStandardUsage) {
    columns.set('standardColumn', 'standardColumn');
  }
  // columns.set('costColumn', 'costColumn');

  columns.set('temperatureColumn-1', 'temperatureColumn');
  if (interval !== 'hour') {
    columns.set('temperatureColumn-2', 'temperatureColumn');
  }

  return columns;
}

function renderColgroup(interval: string, hasTimeOfUseData: boolean, hasSuperOffPeakData: boolean, hasStandardUsage: boolean): JSX.Element {
  const columns = getColumns(interval, hasTimeOfUseData, hasSuperOffPeakData, hasStandardUsage);

  let totalColumns = [...columns.keys()].length;
  let temperatureColumns = 1;
  if (interval !== 'hour') {
    temperatureColumns = 2;
    totalColumns = totalColumns - 1;
  }

  const baseWidth = 100 / totalColumns;

  const colElements = [];
  for (const [key, className] of columns) {
    let width = baseWidth;
    if (className === 'temperatureColumn') {
      width = baseWidth / temperatureColumns;
    }
    const formattedWidth = formatDigits(3).format(width) + '%';
    colElements.push(<col key={key} className={className} style={{ width: formattedWidth }} />);
  }

  return <colgroup>{colElements}</colgroup>;
}

function range(values: Array<number | null | undefined>): [number, number] | null {
  const filtered = values.filter(notNullOrUndefined);
  let min = null;
  let max = null;
  if (filtered.length) {
    min = Math.min(...filtered);
    max = Math.max(...filtered);
  }
  if (min === null || max === null) {
    return null;
  }
  return [min, max];
}

function standardUsageRange(tableData: GraphRecord[]): [number, number] | null {
  const values = tableData.map((record) => {
    return record.values.standard?.usage;
  });
  return range(values);
}

function superOffpeakUsageRange(tableData: GraphRecord[]): [number, number] | null {
  const values = tableData.map((record) => {
    return record.values.superOffpeak?.usage;
  });
  return range(values);
}

function offpeakUsageRange(tableData: GraphRecord[]): [number, number] | null {
  const values = tableData.map((record) => {
    return record.values.offpeak?.usage;
  });
  return range(values);
}

function peakUsageRange(tableData: GraphRecord[]): [number, number] | null {
  const values = tableData.map((record) => {
    return record.values.peak?.usage;
  });
  return range(values);
}

function showRowDividerDay(record: GraphRecord, nextRecord: GraphRecord | null): boolean {
  const peakNow = !!record.values.peak;
  const nextHour = !!nextRecord?.values?.peak;

  return nextHour && !peakNow;
}

function showRowDividerMonth(midpoint: number): boolean {
  return isSaturday(midpoint);
}

function showRowDividerYear(midpoint: number, nextMidpoint: number | null | undefined): boolean {
  // TODO: highlight the border between years

  // Position of this year
  if (nextMidpoint !== undefined && nextMidpoint !== null) {
    return !isSameYear(midpoint, nextMidpoint);
  }

  return false;
}

function showRowDivider(interval: string, record: GraphRecord, nextRecord: GraphRecord | null): boolean {
  if (interval === 'hour') {
    return showRowDividerDay(record, nextRecord);
  }

  if (interval === 'day') {
    return showRowDividerMonth(record.midpoint);
  }

  if (interval === 'month') {
    return showRowDividerYear(record.midpoint, nextRecord?.midpoint);
  }

  return false;
}

function nextRecord(index: number, tableData: GraphRecord[]): GraphRecord | null {
  if (index + 1 < tableData.length) {
    return tableData[index + 1];
  }

  return null;
}

function rowClass(record: GraphRecord, index: number, tableData: GraphRecord[]): string | undefined {
  const interval = record.interval;
  const rowClasses = [];

  const next = nextRecord(index, tableData);
  if (showRowDivider(interval, record, next)) {
    rowClasses.push('intervalDivider');
  }

  if (interval === 'hour' && record.values.peak) {
    rowClasses.push('peak');
  }

  const rowClass = rowClasses.length ? rowClasses.join(' ') : undefined;

  return rowClass;
}

function renderRow(
  record: GraphRecord,
  index: number,
  tableData: GraphRecord[],
  superOffpeakRange: [number, number] | null,
  offpeakRange: [number, number] | null,
  peakRange: [number, number] | null,
  standardRange: [number, number] | null,
  midpoints: number[]
): JSX.Element {
  const interval = record.interval;
  const midpoint = record.midpoint;
  const keyPrefix = interval + '-table-';
  const key = keyPrefix + index;

  const className = rowClass(record, index, tableData);

  const usageColumns = [];

  if (offpeakRange || peakRange) {
    if (superOffpeakRange) {
      usageColumns.push(
        <UsageCell key="superOffpeak" record={record.values.superOffpeak} className="offpeak" range={superOffpeakRange || [0, 0]}/>
      );
    }
    usageColumns.push(
      <UsageCell key="offpeak" record={record.values.offpeak} className="offpeak" range={offpeakRange || [0, 0]} />
    );
    usageColumns.push(
      <UsageCell key="peak" record={record.values.peak} className="peak" range={peakRange || [0, 0]} />
    );
  }

  if (standardRange) {
    usageColumns.push(
      <UsageCell key="standard" record={record.values.standard} className="standard" range={standardRange} />
    );
  }

  return (
    <tr key={key} className={className}>
      <DateCell interval={interval} midpoint={midpoint} midpoints={midpoints} />
      {usageColumns}
      {/* <CostCell graphRecord={record} /> */}
      <TemperatureCell
        interval={interval}
        low={record.temp?.low}
        high={record.temp?.high}
        average={record.temp?.average}
      />
    </tr>
  );
}

interface TableProps {
  tableData: GraphRecord[];
  children?: React.ReactNode;
}
const _Table: React.FunctionComponent<TableProps> = (props: TableProps) => {
  const tableData = props.tableData;
  const interval = tableData[0].interval;
  const standardRange = standardUsageRange(tableData);
  const peakRange = peakUsageRange(tableData);
  const offpeakRange = offpeakUsageRange(tableData);
  const superOffpeakRange = superOffpeakUsageRange(tableData);

  const hasStandardUsage = standardRange !== null;
  const hasTimeOfUseData = peakRange !== null || offpeakRange !== null;
  const hasSuperOffPeakData = superOffpeakRange !== null;

  const midpoints = tableData.map((record: GraphRecord) => {
    return record.midpoint;
  });

  const rows = props.tableData.map((record: GraphRecord, index: number) => {
    return renderRow(record, index, tableData, superOffpeakRange, offpeakRange, peakRange, standardRange, midpoints);
  });

  let tableClass = 'touUsageTable ' + interval + 'Table';
  if (hasTimeOfUseData) {
    tableClass += ' hasPeakData';
  }

  return (
    <React.Fragment>
      <table className={tableClass}>
        {renderColgroup(interval, hasTimeOfUseData, hasSuperOffPeakData, hasStandardUsage)}
        {renderHeader(interval, hasTimeOfUseData, hasSuperOffPeakData, hasStandardUsage)}
        <tbody>{rows}</tbody>
      </table>
    </React.Fragment>
  );
};
const Table = React.memo(_Table, jsonEqual);

interface Props {
  mode: string;
  graphData: GraphRecord[] | null;
  children?: React.ReactNode;
}

function getTableData(graphData: GraphRecord[] | null) {
  if (!graphData) {
    return [];
  }

  let tableData = graphData.slice(1, graphData.length - 1);
  tableData = tableData.filter((value) => {
    const included = includeInterval(value.interval, value.midpoint);
    return included;
  });

  return tableData;
}

export const UsageTable: React.FunctionComponent<Props> = (props: Props) => {
  const tableData = getTableData(props.graphData);

  if (!tableData.length) {
    return null;
  }

  return (
    <Accordion>
      <ExpandContractButton eventKey="0">Detailed Usage Table</ExpandContractButton>
      <Accordion.Collapse eventKey="0">
        <Card.Body className="p-0 peakTimeSavingsTableWrapper">
          <Table tableData={tableData} />
        </Card.Body>
      </Accordion.Collapse>
    </Accordion>
  );
};
