import { UrlDetails } from '../../util/AuthManager';
import { uniqueValues, sumOrNull } from '../../util/utils';
import { UsageRecord } from '../UsageData/UsageRecord';
import { UsageData } from './TimeOfUseData';
import { uniqueRecordsByMeter } from './uniqueRecordsByMeter';

function combineRecords(records: UsageData[]): UsageData {
  const filteredRecords = uniqueRecordsByMeter(records);

  const combinedData = { ...filteredRecords[0].record };

  const costs = filteredRecords.map((record) => {
    return record.costEstimate;
  });
  combinedData.costEstimate = sumOrNull(costs);

  const usage = filteredRecords.map((record) => {
    return record.usage;
  });
  combinedData.usage = sumOrNull(usage);

  const expected = filteredRecords.map((record) => {
    return record.expectedHours;
  });
  combinedData.expectedHours = expected.reduce((a, b) => a + b, 0) / expected.length;

  const missingHours = filteredRecords.map((record) => {
    return record.missingHours;
  });
  combinedData.missingHours = Math.max(...missingHours);

  const missingUsage = filteredRecords.map((record) => {
    return record.missingUsageEstimate;
  });
  combinedData.missingUsageEstimate = sumOrNull(missingUsage);

  const missingCosts = filteredRecords.map((record) => {
    return record.missingCostEstimate;
  });
  combinedData.missingCostEstimate = sumOrNull(missingCosts);

  return new UsageData(filteredRecords[0].interval, combinedData);
}

function recordsByMidpoint(records: UsageData[]): Map<number, UsageData[]> {
  const condensed = new Map<number, UsageData[]>();

  for (const record of records) {
    const midpoint = record.midpoint;
    let newRecords = condensed.get(midpoint);
    if (!newRecords) {
      newRecords = [];
    }
    newRecords.push(record);
    condensed.set(midpoint, newRecords);
  }

  return condensed;
}

function combineByMidpoint(records: UsageData[]): UsageData[] {
  const condensed = recordsByMidpoint(records);

  const combined = new Array<UsageData>();

  for (const midpointRecords of condensed.values()) {
    combined.push(combineRecords(midpointRecords));
  }

  return combined;
}

function sameType(record: UsageRecord, records: UsageRecord[]) {
  const filtered = records.filter((compareRecord) => {
    let same = record.readingType === compareRecord.readingType;
    same = same && record.rateCategory === compareRecord.rateCategory;
    same = same && record.timeOfDay === compareRecord.timeOfDay;
    return same;
  });
  return filtered;
}

function sumUsage(records: UsageRecord[]): number | null {
  const values = records.map((record) => {
    return record.usage;
  });
  return sumOrNull(values);
}

function sumCost(records: UsageRecord[]): number | null {
  const values = records.map((record) => {
    return record.costEstimate;
  });
  return sumOrNull(values);
}

function sumHours(records: UsageRecord[]): number | null {
  const values = records.map((record) => {
    return record.expectedHours - record.missingHours;
  });
  return sumOrNull(values);
}

function averageUsagePerHour(records: UsageRecord[]): number | null {
  const total = sumUsage(records);
  const hours = sumHours(records);
  return total && hours ? total / hours : null;
}

function averageCostPerHour(records: UsageRecord[]): number | null {
  const total = sumCost(records);
  const hours = sumHours(records);
  return total && hours ? total / hours : null;
}

function estimatedValue(averagePerHour: number | null | undefined, hours: number | null | undefined): number | null {
  if (hours === null || hours === undefined) {
    return null;
  }

  // Just use a small value if we don't have any data
  if (averagePerHour === null || averagePerHour === undefined) {
    return 0.05;
  }

  return averagePerHour * hours;
}

function data(interval: string, usage: UsageRecord[]): UsageData[] {
  const values = [];

  // For now, skip anything that's not an expected type of usage (like generation)
  const filtered = (usage || []).filter((record) => {
    return ['KWH', 'CCF'].includes(record.readingType);
  });

  for (const record of filtered) {
    // Add in  estimates for missing data
    if (record.missingHours) {
      const similarRecords = sameType(record, filtered);
      const usageAverage = averageUsagePerHour(similarRecords);
      const costAverage = averageCostPerHour(similarRecords);
      record.missingUsageEstimate = estimatedValue(usageAverage, record.missingHours);
      record.missingCostEstimate = estimatedValue(costAverage, record.missingHours);
    }

    const tempData = new UsageData(interval, record);
    if (tempData.date) {
      values.push(tempData);
    }
  }
  return values;
}

function hasData(records: UsageData[]): boolean {
  return records?.length > 0;
}

function hasCost(records: UsageData[]): boolean {
  for (const record of records) {
    if (record.costEstimate !== null) {
      return true;
    }
  }
  return false;
}

function hasMissingData(records: UsageData[]): boolean {
  // Missing data if any of the values are null
  for (const record of records) {
    if (record.hasMissingData) {
      return true;
    }
  }
  return false;
}

function hasPartialData(records: UsageData[]): boolean {
  for (const record of records) {
    if (record.hasPartialData) {
      return true;
    }
  }
  return false;
}

function midpoints(records: UsageData[]): number[] {
  const values = records.map((record) => {
    return record.midpoint;
  });
  return uniqueValues(values);
}

function dataByTimeOfDay(records: UsageData[], timeOfDay: string): UsageData[] {
  const filtered = records.filter((record) => {
    return record.timeOfDay === timeOfDay;
  });
  return combineByMidpoint(filtered);
}

function minUsage(records: UsageData[]): number {
  let minValue = 0;

  const combined = combineByMidpoint(records);
  for (const record of combined) {
    minValue = Math.min(minValue, record.usage || 0);
  }

  return minValue;
}

function maxUsage(records: UsageData[]): number {
  let maxValue = 0;

  const combined = combineByMidpoint(records);
  for (const record of combined) {
    maxValue = Math.max(maxValue, record.usage || 0);
  }

  return maxValue;
}

function maxCost(records: UsageData[]): number {
  let maxValue = 0;

  const combined = combineByMidpoint(records);
  for (const record of combined) {
    maxValue = Math.max(maxValue, record.costEstimate || 0);
  }

  return maxValue;
}

function hasTimeOfUseData(peakUsage: UsageData[], offpeakUsage: UsageData[], superOffpeakUsage: UsageData[]): boolean {
  return peakUsage.length + offpeakUsage.length + superOffpeakUsage.length > 0;
}

function hasSuperOffPeakTimeOfUseData(superOffpeakUsage: UsageData[]): boolean {
  return superOffpeakUsage.length > 0;
}

/**
 * Get all the data associated with a specifc point in time
 *
 * @param midpoint unix timestamp
 */
function dataByMidpoint(records: UsageData[], midpoint: number): UsageData[] {
  const filtered = records.filter((record) => {
    return record.midpoint === midpoint;
  });
  return filtered;
}

export class UsageGraphHelper {
  readonly interval: string;
  readonly usage: UsageRecord[];
  readonly data: UsageData[];
  readonly hasData: boolean;
  readonly hasCost: boolean;
  readonly hasMissingData: boolean;
  readonly hasPartialData: boolean;
  readonly midpoints: number[];
  readonly superOffpeakUsage: UsageData[];
  readonly peakUsage: UsageData[];
  readonly offpeakUsage: UsageData[];
  readonly standardUsage: UsageData[];
  readonly minUsage: number;
  readonly maxUsage: number;
  readonly maxCost: number;
  readonly hasTimeOfUseData: boolean;
  readonly hasSuperOffPeakTimeOfUseData: boolean;
  readonly url: UrlDetails;

  constructor(url: UrlDetails, interval: string, usage: UsageRecord[]) {
    this.url = url;
    this.interval = interval;
    this.usage = usage;
    this.data = data(interval, usage);
    this.hasData = hasData(this.data);
    this.hasCost = hasCost(this.data);
    this.hasMissingData = hasMissingData(this.data);
    this.hasPartialData = hasPartialData(this.data);
    this.midpoints = midpoints(this.data);
    this.offpeakUsage = dataByTimeOfDay(this.data, 'OFFPEAK');
    this.superOffpeakUsage = dataByTimeOfDay(this.data, 'SUPEROFFPEAK');
    this.peakUsage = dataByTimeOfDay(this.data, 'PEAK');
    this.standardUsage = dataByTimeOfDay(this.data, '');
    this.minUsage = minUsage(this.data);
    this.maxUsage = maxUsage(this.data);
    this.maxCost = maxCost(this.data);
    this.hasTimeOfUseData = hasTimeOfUseData(this.peakUsage, this.offpeakUsage, this.superOffpeakUsage);
    this.hasSuperOffPeakTimeOfUseData = hasSuperOffPeakTimeOfUseData(this.superOffpeakUsage);
  }

  public maxInterval(mode: string): number {
    if (mode === 'cost') {
      return this.maxCost;
    }
    return this.maxUsage;
  }

  public dataByMidpointAndTimeOfDay(midpoint: number, timeOfDay: string): UsageData | null {
    let filtered = dataByMidpoint(this.data, midpoint);
    filtered = dataByTimeOfDay(filtered, timeOfDay);
    if (!filtered.length) {
      return null;
    }
    return combineRecords(filtered);
  }

  // TODO: at some point we'll need to handle other types of usage
}
